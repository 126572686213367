import React, { useCallback, useEffect, useState } from "react";
import TakePhoto from "../TakePhoto";
import useUpload from "../../hooks/useUpload";
import {compress} from "../../helpers/image";
import {Category} from "../../models/Category";
import {Store} from "../../models/Attendance";

interface UploadProps {
    onComplete(response: any): void;
    onChange?(data: any): void;
    isUpload?: boolean;
    required?: boolean;
    useCapture?: boolean;
    value?: string;
    label?: string;
    category?: Category;
    store?: Store;
}

const Upload: React.FC<UploadProps> = ({ store, category, useCapture, value, label, required, onComplete }) => {
    const handleChange = useCallback(async ({ target: { value } }) => {
        onComplete({photo_url: value});
    }, [onComplete]);
    return (
        <div className="upload">
            <TakePhoto
                store={store}
                category={category}
                useCapture={useCapture}
                label={label}
                defaultValue={value}
                required={required}
                onChange={handleChange}
            />
        </div>
    );
};

export default Upload;
