import React from "react";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import AnswerField from "./AnswerField";
import {QuestionProps} from "./Question";
import {FieldCondition} from "../../models/Question";
import {Category} from "../../models/Category";
import {Store} from "../../models/Attendance";
import { checkConditionsToShow } from "./func";

interface QuestionFieldProps extends QuestionProps {
    answers: any;
    category?: Category;
    store?: Store;
    onChange(answers: any): void;
}

const Td: React.FC<{ question: string }> = React.memo(({question}) => {
    return <td className={"question-name"}>{question}</td>;
});

class QuestionField extends React.Component<QuestionFieldProps, {}> {

    shouldComponentUpdate(nextProps: Readonly<QuestionFieldProps>): boolean {
        return !isEqual(this.props, nextProps);
        // return JSON.stringify(this.props.answers) !== JSON.stringify(nextProps.answers) ||
        //     this.props.isSubmitting !== nextProps.isSubmitting ||
        //     this.props.question.id !== nextProps.question.id
    }

    handleChange = (fieldId: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLAreaElement | any>) => {
        const {onChange, answers} = this.props;
        let nextValue = e;

        if (e && e.target) {
            const {checked, value, type, files} = e.target;
            nextValue = value;
            if (type === "checkbox") {
                nextValue = checked ? value : null;
            } else if (type === "radio") {
                nextValue = checked ? value : null;
            } else if (files && type === "file" && files.length) {
                nextValue = files[0];
            }
        }

        const next = {...answers, [fieldId]: nextValue};
        onChange(next);
    };

    protected checkConditionsToShow(conditions: FieldCondition[]): boolean {
        const {answers} = this.props;
        return checkConditionsToShow(answers, conditions);
    }

    render() {
        const {answers,  category, store, question, isSubmitting} = this.props;
        return (
            <tr key={question.id}>
                <Td question={question.question}/>
                <td className="question-field">
                    {
                        isArray(question.options) && question.options.map((option, index) => {
                            const {type, id, conditions} = option;
                            if (conditions && conditions.length && !this.checkConditionsToShow(conditions)) {
                                return null;
                            }
                            const value = answers && typeof answers[id] !== "undefined" ? answers[id] : null;
                            return (
                                <div className="d-block mb-1" key={index}>
                                    <AnswerField
                                        category={category}
                                        store={store}
                                        type={type}
                                        onChange={this.handleChange(id)}
                                        data={option}
                                        value={value}
                                        submitting={isSubmitting}
                                    />
                                </div>
                            );
                        })
                    }
                </td>
            </tr>
        );
    }
}

export default QuestionField;