import React, {useEffect, useState} from "react";
import {useInterval} from "react-use";
import useLogout from "../../hooks/useLogout";

const ThankPage: React.FC = () => {
    const [count, setCount] = useState(0);
    const logout = useLogout(false);

    useInterval(() => {
        setCount(count + 1);
    }, 1000);

    const time = 5 - count;

    useEffect(() => {
        if(time <= 0){
            logout();
            window.location.href = "/";
        }
    }, [time, logout]);

    return (
        <div id="thank_page" className="page-center container-fluid">
            <div className="row flex-grow-1 text-center justify-content-center align-items-center">
                <div className="col-12 col-lg-6 col-xl-3">
                    <div className="bg-white shadow px-2 py-4 w-100 rounded">
                        <img src="images/logo-smollan.svg" alt="Smollan Logo" className="img-fluid mb-4"/>
                        <p className="display-4">Cám ơn</p>
                        <p className="display-4 mb-5">hẹn gặp lại</p>
                        <p>Quay về trang chủ trong <span className="text-danger">{time}s</span></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThankPage;
