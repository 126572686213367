import React, { useState, useMemo } from "react";
import Question from "../../components/Question";
import Authenticated from "../../components/Authenticated";
import QuestionGroup from "../../components/QuestionGroup";
import Logo from "../../components/Logo";
import { Category } from "../../models/Category";
import { match } from "react-router-dom";
import ChangeSchedule from "../Attendance/ChangeSchedule";
import { Scheduled } from "../../models/Attendance";
import moment from "moment";
import useAttendance from "../../hooks/useAttendance";

const CheckAttendance: React.FC<{
    scheduled: Scheduled;
    id: string | number;
}> = ({ scheduled, id }) => {
    const date = useMemo(() => moment().format("YYYY-MM-DD"), []);
    const [category, setCategory] = useState<null | Category>();
    const result = useAttendance({date, staff_schedule_id: scheduled.id});

    if (result.data && (result.data.status || result.data.day_off)) { //  || !result.data.store_is_opening
        return (
            <p className={"text-center bg-white shadow-sm font-weight-bold text-info py-3"}>
                Bạn đã báo nghỉ phép, cửa hàng đóng cửa hoặc đang bị khóa
            </p>
        );
    }

    return (
        <div className="w-100">
            {id && <QuestionGroup key={id} id={+id} onClick={setCategory} />}
            {category && <Question key={`${category.id}-${scheduled.id}`} scheduled={scheduled} category={category} />}
        </div>
    );
}

const QuestionPage: React.FC<{ match: match<{ id: string }> }> = ({ match: { params: { id } } }) => {
    const [scheduled, setScheduled] = useState<Scheduled | null>(null);
    return (
        <Authenticated>
            <div className="attendance_page p-md-2 font-roboto">
                <div className="bg-light shadow p-2 min-vh-100" data-id={scheduled?.id}>
                    <Logo />
                    <ChangeSchedule onChange={setScheduled} activeId={scheduled?.id} />
                    <br />
                    {scheduled && <CheckAttendance scheduled={scheduled} id={id} />}
                </div>
            </div>
        </Authenticated>
    );
};

export default QuestionPage;
