import React, {useEffect, useState} from "react";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import forIn from "lodash/forIn";
import {Modal, Message, Button} from "semantic-ui-react";

const Error: React.FC<{ errors: any, type?: string }> = ({errors}) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (isObject(errors) && !isEmpty(errors)) {
            setShow(true);
        }
        if (isArray(errors) && errors.length) {
            setShow(true);
        }
        return () => {
        };
    }, [errors]);

    const messsages: any[] = [];
    if (isObject(errors)) {
        forIn(errors, function(value, key){
            messsages.push(
                <Message negative key={key}>
                    <div dangerouslySetInnerHTML={{__html: value}}/>
                </Message>
            );
        });
    }
    return (
        <div className="errors">
            <Modal
                open={show}
                   onClose={() => setShow(false)}>
                <Modal.Content>{ messsages }</Modal.Content>
                <Modal.Actions>
                    <Button color={"google plus"} onClick={() => setShow(false)}>Hủy</Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default Error;
