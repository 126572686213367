import React, { ImgHTMLAttributes } from "react";
import { env } from "../../env";

const Image: React.FC<ImgHTMLAttributes<HTMLElement>> = ({ src, ...props }) => {
    let fixSrc = src;
    if (typeof src === "string" && src.search("http") === -1) {
        fixSrc = env.domain + "/" + src?.replace("public/", "storage/");
    }
    return <img alt="" {...props} src={fixSrc} />
};

export default Image;
