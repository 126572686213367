import React, {useMemo} from "react";
import {types} from "./types";
import {Input} from "semantic-ui-react";
import {Category} from "../../models/Category";
import {Store} from "../../models/Attendance";

interface AnswerFieldProps {
    value?: any;

    onChange(data: any): void;

    data: any;
    type: string;
    submitting: boolean;
    category?: Category;
    store?: Store;
}

const AnswerField: React.FC<AnswerFieldProps> = ({store, category, value, submitting, type, onChange, data}) => {

    const Component = React.useMemo(() => {
        return types[type] ? React.memo(types[type]) : React.memo(Input);
    }, [type]);

    const next = useMemo(() => {
        const next = {...data};
        if (next.required && +next.required === 0) {
            delete next.required;
        }
        if (next.placeholder) {
            next.label = next.placeholder;
        }
        return next;
    }, [data]);

    return <Component fluid submitting={submitting}
                      category={category}
                      store={store}
                      value={value} onChange={onChange} {...next} />;
};

export default React.memo(AnswerField);
