import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Button, Loader, Message} from "semantic-ui-react";
import {Category} from "../../models/Category";
import useQuestion from "../../hooks/useQuestion";
import {useRequest} from "../../hooks/useRequest";
import {Answer, Attribute} from "../../models/Question";
import moment from "moment";
import {Scheduled} from "../../models/Attendance";
import urls from "../../settings/urls";
import {useFetch} from "../../hooks/useFetch";
import {AnswerContext} from "./AnswerContext";
import GroupQuestions from "./Group";
import SearchQuestion from "./Search";
import {useLocalStorage, useCookie} from "react-use";
import {isArray, isEmpty} from "lodash";
import {checkConditionsToShow, isAnserwed} from "./func";
import {upload} from "../../hooks/useUpload";
import constants from "../../settings/constants";
import TotalPrice from "./partials/TotalPrice";
import AutoRedirect from "../Navigate/AutoRedirect";
import ChooseAnswer from "./ChooseAnswer";
import DeleteAnswer from "./DeleteAnswer";

interface QuestionProps {
    category: Category;
    scheduled: Scheduled;
}

const typeFiles = ['photo', 'gallery'];

const Question: React.FC<QuestionProps> = ({scheduled, category}) => {
    const [state, setState] = useState<{ [key: number]: any }>({});
    const date = useMemo(() => moment().format("YYYY-MM-DD"), []);
    const currentId = useRef<number | null>();
    const {loading, data, errors} = useQuestion(category.id, {
        store_id: scheduled.store.id,
        schedule_id: scheduled.id
    });

    const [result, request] = useRequest(urls.answer);
    const COOKIE = `${category.id}_${scheduled.staff_id}_${scheduled.store.id}_${date}`;
    const [stateCookie, updateStateCooke, deleteStateCookie] = useLocalStorage(COOKIE, "");
    const [message, setMessage] = useState("");
    const [token] = useCookie(constants.AUTH_TOKEN);
    const fetchAnswers = useFetch<Answer[]>(urls.answer, {
        date: date,
        store_id: scheduled.store.id,
        category_id: category.id
    });

    const {collapsed, hideGroupName, allowRepeat} = useMemo(() => {
        if (!category.metas) {
            return {
                hideGroupName: false,
                collapsed: false,
            };
        }
        const map = new Map(category.metas.map(node => [node.meta_key, node.meta_value]));
        const collapsed = !!map.get("un_collapsed");
        const hideGroupName = !!map.get("hide_group_name");
        const allowRepeat = !!map.get("allow_repeat");
        return {hideGroupName, allowRepeat, collapsed};
    }, [category]);

    /** Cờ thông báo đã sử dụng câu trả lời được kiểm tra từ server */
    const [isUseAnswered, setUseAnswerd] = useState(false);
    const [isUseCookie, setUseCookie] = useState(false);
    useEffect(() => {
        if (allowRepeat) return;
        if (!isUseCookie && fetchAnswers.isFetched && !isUseAnswered && fetchAnswers.data && fetchAnswers.data.length) {
            const {data} = fetchAnswers;
            setState((prevState) => {
                const next: any = {...prevState};
                data.map(node => {
                    return next[node.question_id] = node.content;
                });
                return next;
            });
            setUseAnswerd(true);
        }
    }, [fetchAnswers, allowRepeat, state, setState, isUseCookie, isUseAnswered]);

    useEffect(() => {
        if (!isUseCookie && !isUseAnswered && !Object.keys(state).length) {
            if (stateCookie && window.confirm("Tìm thấy dữ liệu được lưu dưới máy, bạn có muốn sử dụng ?")) {
                try {
                    const next = JSON.parse(stateCookie);
                    setState(next);
                    setMessage("Tìm thấy dữ liệu trên máy chưa được lưu, hãy nhấn nút LƯU THÔNG TIN nhé");
                } catch (e) {
                    alert("Có lỗi sảy ra");
                    console.log(e);
                }
                setUseCookie(true);
            } else {
                deleteStateCookie();
            }
        }
    }, [stateCookie, isUseCookie, deleteStateCookie, isUseAnswered, state, setState]);

    const [isSubmitting, setSubmitting] = useState(false);

    const getParams = useCallback(async (value: any) => {
        const questionMap = new Map<number, Attribute[]>();
        data && data.map(({questions}) => {
            return questions.map((node) => {
                questionMap.set(node.id, node.options);
            })
        });
        const params: any[] = [];
        for (const key in value) {
            const question = questionMap.get(Number(key));
            const answers = value[key];
            if (question) {
                for (const node of question) {
                    if (answers[node.id] instanceof Blob) {
                        try {
                            const uploaded: any = await new Promise((resolve, reject) => {
                                upload({
                                    file: answers[node.id],
                                    path: `${scheduled.store.store_name}/${date}/${category.name}/${node.placeholder}`
                                }, snapshot => {
                                    if (snapshot.status === "done") {
                                        return resolve(snapshot.response);
                                    } else if (snapshot.status === "error") {
                                        return reject(snapshot.response);
                                    }
                                }, token);
                            });
                            if (uploaded) {
                                answers[node.id] = uploaded.photo_url;
                            }
                            console.log(uploaded);
                        } catch (error) {
                            alert("Có lỗi khi tải lên: " + node.placeholder);
                        }
                    }
                }
            }
            const param: any = {
                question_id: key,
                content: answers,
                date: date,
                store_id: scheduled.store.id,
                category_id: category.id
            };
            if (currentId.current) {
                param.id = currentId.current;
            }
            params.push(param);
        }
        return params;
    }, [category.id, data, date, scheduled.store.id, token]);

    const handleSubmit = useCallback(async (e?: any) => {
        e && e.preventDefault();
        const questionMap = new Map<number, Attribute[]>();
        let checkRequired = "";

        if (data) {
            data.map(({questions}) => {
                return questions.map((node) => {
                    questionMap.set(node.id, node.options);
                    const find = node.options && isArray(node.options) && node.options.find(option => {
                        const {required, conditions, id} = option;
                        if (required) {
                            if (conditions && conditions.length) {
                                /**
                                 * Có điều kiện nhưng không có câu trả lời của câu điều kiện thì không cần bắt buộc trả lời
                                 */
                                if (!state[node.id]) {
                                    return false;
                                } else if (!checkConditionsToShow(state[node.id], conditions)) {
                                    /**
                                     * Có câu trả lời của câu điều kiện nhưng không đáp ứng được điều kiện của câu cần show
                                     */
                                    return false;
                                }
                            }
                            if (state[node.id]) {
                                const value = state[node.id][id];
                                return !isAnserwed(value);
                            }
                            return true;
                        }
                        return false;
                    });
                    if (find) {
                        checkRequired += `\n${node.question}: ${find.placeholder}`;
                    }
                })
            });
        }
        if (checkRequired) {
            alert(`Vui lòng hoàn thành những câu hỏi bắt buộc: ${checkRequired}`);
            return;
        }
        setSubmitting(true);
        const params = await getParams(state);
        request({data: params});
        setSubmitting(false);
    }, [data, getParams, request, state]);

    const [isUsedDefaultValue, setUsedDefaultValue] = useState(false);

    useEffect(() => {
        if (!data || !data.length || isUsedDefaultValue) {
            return;
        }
        setState(prevState => {
            const next: any = {...prevState};
            data.map(group => {
                group.questions.map(question => {
                    question.options.map(({defaultValue, id}) => {
                        if (!defaultValue) {
                            return;
                        }
                        if (!next[question.id]) {
                            next[question.id] = {};
                        }
                        if (typeof next[question.id][id] === "undefined") {
                            console.log(next[question.id]);
                            next[question.id][id] = defaultValue;
                        }
                    })
                });
            });
            return next;
        });
        setUsedDefaultValue(true);
    }, [data, isUseAnswered, isUsedDefaultValue]);

    const onChange = useCallback((next: any) => {
        setState({...next});
        updateStateCooke(JSON.stringify(next));
        setUseAnswerd(true);
    }, [updateStateCooke, setState, setUseAnswerd]);

    const isDone = React.useMemo(() => {
        return !!(isArray(result.data) && result.isFetched && result.data.length);
    }, [result.data, result.isFetched]);

    const isError = React.useMemo(() => {
        return result.isFetched ? (!isArray(result.data) || !result.data.length) : false;
    }, [result.data, result.isFetched]);

    // Save if submited
    useEffect(() => {
        if (isDone) {
            /**
             * Delete cookie if data upload success
             */
            deleteStateCookie();
            setTimeout(() => {

            }, 1000);
        }
    }, [isDone, deleteStateCookie]);

    const sendRequestError = useMemo(() => {
        if (result.errors) {
            try {
                return result.errors.toString();
            } catch (error) {
                alert(error);
                return "Hành động không thể hoàn thành";
            }
        } else {
            return "";
        }
    }, [result.errors]);

    const submitText = useMemo(() => {

        if (isDone) {
            return "Đã hoàn thành";
        }

        if (isError) {
            return "Lưu thất bại";
        }

        if (isSubmitting) {
            return "Đang tiến hành lưu";
        }

        return "Lưu thông tin";
    }, [isDone, isError, isSubmitting]);
    // console.log(loading , fetchAnswers.loading, state);

    if (loading || fetchAnswers.loading) {
        return <Loader active/>
    }
    // {category.id === 68 && <TotalPrice value={state} data={data}/>}
    return (
        <AnswerContext.Provider value={{value: state, onChange}}>
            <AutoRedirect active={isDone} href={'/menu'}/>
            <div className="questions bg-white shadow-sm pt-2">
                <div className="p-1">
                    {errors && <Message color='red'>{errors.toString()}</Message>}
                    {message && <Message color='pink'>{message.toString()}</Message>}
                </div>
                {
                    data && data.length ?
                        (
                            <div className="w-100">
                                <SearchQuestion isSubmitting={isSubmitting} groups={data}/>
                                <p className={"font-weight-bold p-2 mb-0"}>DANH MỤC CÂU HỎI</p>
                            </div>
                        ) : (
                            <p className={"text-center font-weight-bold ui red"}>
                                Danh mục không có câu hỏi.
                            </p>
                        )
                }
                {
                    allowRepeat && (
                        <div className="w-100 px-2">
                            <ChooseAnswer
                                answers={fetchAnswers?.data ?? []}
                                onChange={answer => {
                                    if (answer) {
                                        currentId.current = answer.id;
                                        setState({[answer.question_id]: answer.content});
                                    } else {
                                        currentId.current = null;
                                        setState({});
                                    }
                                }}
                            />
                        </div>
                    )
                }
                <form action="#" onSubmit={handleSubmit}>
                    <table key={"" + currentId.current} className={"table ui my-0 small border-0 bg-transparent"}>
                        {
                            data && data.map(({group, questions}, index) => {
                                return <GroupQuestions
                                    hideGroupName={hideGroupName}
                                    collapsed={collapsed}
                                    group={group}
                                    key={index}
                                    questions={questions}
                                    category={category}
                                    store={scheduled.store}
                                    isSubmitting={isSubmitting}/>;
                            })
                        }
                    </table>
                    {
                        data && data.length ? (
                            <div className="p-2 d-flex mb-2 ">
                                {
                                    currentId.current && allowRepeat && (
                                            <DeleteAnswer id={currentId.current} onDeleted={() => {
                                                currentId.current = null;
                                                setState({});
                                                fetchAnswers.refresh();
                                            }}/>
                                    )
                                }
                                <Button
                                    disabled={!Object.keys(state).length}
                                    type={"submit"}
                                    color={isDone ? "facebook" : (isError ? "google plus" : "black")}
                                    loading={result.loading || fetchAnswers.loading}
                                    className="text-uppercase btn-block">
                                    {submitText}
                                </Button>
                            </div>
                        ) : null
                    }
                    {message && <Message color='pink'>{message.toString()}</Message>}
                    {sendRequestError && <Message color='red'>{sendRequestError}</Message>}
                </form>
            </div>
        </AnswerContext.Provider>
    );
};

export default React.memo(Question);
