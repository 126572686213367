import React, {useCallback, useMemo, useState} from "react";
import _ from "lodash";
import {Button, FormSelect, Input} from "semantic-ui-react";

const MyRepeat: React.FC<any> = ({placeholder, classes, options, value, onChange}) => {
    const [name, setName] = useState<any>("");

    const addMore = useCallback(() => {
        const next = _.isArray(value) ? value : [];
        next.push({name});
        onChange(next);
        setName("");
    }, [onChange, name, value]);

    const remove = useCallback((index: number) => () => {
        if (!window.confirm("Bạn có chắc không ?")) return;
        const next = _.isArray(value) ? value : [];
        onChange(next.filter((n, i) => i !== index));
    }, [onChange, value]);

    const change = useCallback((index: number, name: string) => ($e: any, $v: any) => {
        const next = _.isArray(value) ? value : [];
        next[index][name] = $v.value;
        onChange(next);
    }, [onChange, value]);

    const option = options.filter((node: any) => {
        if (!_.isArray(value)) return true
        const find = value.find(child => child.name === node.value)
        console.log(find)
        return !find
    }).map(({value}: any, index: number) => ({
        key: index,
        value,
        text: value
    }));

    return (
        <div className="ui form">
            <div className="grouped fields">
                <div className={classes}>
                    {placeholder && <label className={"ui label w-100"}>{placeholder}</label>}
                    <div className={"field-repeat mb-2"}>
                        {
                            _.isArray(value) && value?.map((node: any, index: number) => {
                                const find = options.find(({value}: any) => value === node.name);
                                return (
                                    <div key={index}>
                                        <div className={"text-xs my-1"}>
                                            {node.name}
                                        </div>
                                        <div className="rows">
                                            <div className={"pr-2"}>
                                                <Input
                                                    required
                                                    className={"w-100"}
                                                    value={node.value}
                                                    onChange={change(index, "value")}
                                                    type={find?.type}
                                                />
                                            </div>
                                            <div>
                                                <Button color='red' onClick={remove(index)} type={"button"}>Xóa</Button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        !!option.length && (
                            <div>
                                <div className={"pr-2"}>
                                    <p className={"mb-0"}>Chọn sản phẩm</p>
                                    <FormSelect
                                        value={name}
                                        onChange={($e, $v) => setName($v.value)}
                                        options={option}
                                    />
                                </div>
                                <Button disabled={!name} onClick={addMore} type={"button"}>THÊM MỚI</Button>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default React.memo(MyRepeat);
