import {FieldCondition} from "../../models/Question";
import isArray from "lodash/isArray";

/** Kiểm tra danh sách các field đã trả lời hay chưa ? */
export function isAnserwed(fields: any) {
    if(typeof fields === "object") {
        return fields && !!Object.keys(fields).find(key => {
            return isAnsweredNotEmpty(fields[key]);
        });
    }else{
        return isAnsweredNotEmpty(fields);
    }
}

/** Kiểm tra câu trả lời là rỗng hay không */
export function isAnsweredNotEmpty(value: any) {
    return value !== null && value !== "";
}


export function checkConditionsToShow(answers: any, conditions: FieldCondition[]): boolean {
    for(const {field_id, option, value} of conditions){
        let prev = answers[field_id];
        if(typeof prev === 'undefined'){
            prev = '';
        }
        switch (option) {
            case "eq": return value === prev;
            case "lt": return value <= prev;
            case "gt": return value >= prev;
            case "ne": return value != prev;
            case "in": return isArray(prev) && prev.includes(value);
            case "no": return isArray(prev) && !prev.includes(value) || true;
        }
    }
    return true;
}