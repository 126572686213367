import React, {useCallback, useEffect, useMemo, useState} from "react";
import isArray from "lodash/isArray";
import {random} from "lodash";
import {isAnsweredNotEmpty} from "../func";

const RadioCustom: React.FC<any> = React.memo(({onChange, checked, placeholder, value}) => {
    const [isChecked, setChecked] = React.useState(checked);

    const [storeValue, setStoreValue] = React.useState("");

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        onChange(e);
        setStoreValue(value);
        setChecked(true);
    }, [onChange, setStoreValue, setChecked]);

    useEffect(() => {
        setChecked(value === storeValue);
    }, [value, storeValue]);

    useEffect(() => {
        if (checked) {
            setStoreValue(value);
        }
    }, [checked, value, setStoreValue, setChecked]);

    const handleCheckbox = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const {checked} = e.target;
        if (checked) {
            onChange(e);
            setChecked(true);
        }
    }, [storeValue, onChange, setChecked]);

    return (
        <div className="ui radio checkbox fluid w-100">
            <input checked={isChecked} type="radio" onChange={handleCheckbox} value={storeValue}/>
            <label>
                <input onChange={handleChange}
                       className={`w-100 ${isChecked && !isAnsweredNotEmpty(value) ? "border-danger" : ""}`}
                       placeholder={placeholder} type="text"
                       value={storeValue}/>
            </label>
        </div>
    );
});

const Radio: React.FC<any> = ({label, onChange, value, checked}) => {
    const optionID = `radio-${random(1000000)}`;
    return (
        <div className="ui radio checkbox fluid w-100">
            <input id={optionID} onChange={onChange} checked={checked} type="radio" value={value}/>
            <label htmlFor={optionID}>{value || label}</label>
        </div>
    );
};

const MyRadio: React.FC<any> = ({placeholder, options, classes, value, onChange, radio_hide_status = false}) => {
    const customChecked = useMemo(() => {
        const find = options.find((node: any) => node.value === value && node.type !== "custom");
        return !!find;
    }, [value, options]);

    const [isShow, setShow] = useState(radio_hide_status);

    useEffect(() => {
        if (radio_hide_status) {
            setShow(true);
        } else {
            setShow(value !== null && value !== "Có");
        }
    }, [value, radio_hide_status]);

    return (
        <div className="ui form">
            <div className="grouped fields">
                <div className={classes}>
                    {placeholder && <label>{placeholder}</label>}
                    {
                        !radio_hide_status && (
                            <>
                                <div className="d-flex align-items-center">
                                    <Radio className={"mr-2"} checked={value === "Có"} value={"Có"}
                                           onChange={onChange}/>
                                    <Radio checked={isShow} label={"Không"} value={""} onChange={onChange}/>
                                </div>
                                <hr/>
                            </>
                        )
                    }
                    {
                        isShow && isArray(options) && options.length ? (
                            options.map((option, index) => {
                                let component: any;
                                switch (option.type) {
                                    case "custom":
                                        component = <RadioCustom
                                            checked={!customChecked}
                                            value={value}
                                            placeholder={option.value}
                                            onChange={onChange}/>;
                                        break;
                                    default:
                                        component = <Radio value={option.value} checked={option.value === value}
                                                           onChange={onChange}/>
                                        break;
                                }
                                return (
                                    <div className="field" key={index}>
                                        {component}
                                    </div>
                                );
                            })
                        ) : null
                    }
                </div>
            </div>
        </div>
    );
};

export default React.memo(MyRadio);
