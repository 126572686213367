import {useCallback, useEffect, useState} from "react";
import {Response} from "../models/Response";
import axios from "axios";
import {sendError} from "../services/error";

export const useFetch = <T extends any>(uri?: string | null, data: any = null): Response<T | null> & { refresh: any; request: any } => {

    const [state, setState] = useState<Response<T | null>>({
        data: null,
        loading: false,
        errors: null,
        isFetched: false
    });

    const request = useCallback((uri: string, data: any) => {
        const source = axios.CancelToken.source();
        setState({loading: true, data: null, errors: null});

        axios.get(uri, {params: data, cancelToken: source.token}).then((response) => {
            setState({loading: false, data: response.data, errors: null, isFetched: true});
        }).catch(errors => {
            sendError(uri, JSON.stringify(errors), data);
            setState({loading: false, data: null, errors, isFetched: true});
        });

        return () => {
            source.cancel('Operation canceled by the user.')
        };
    }, [])

    useEffect(() => {
        if (!uri) {
            return;
        }
        request(uri, data);
    }, [uri, request, JSON.stringify(data)]);

    const refresh = useCallback(() => uri && request(uri, data), [uri, data]);

    return {
        ...state,
        request,
        refresh
    };
};
