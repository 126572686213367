import React, { useState, useContext, useMemo } from "react";
import { Question as IQuestion } from "../../models/Question";
import Question from "./Question";
import { Category } from "../../models/Category";
import { AnswerContext } from "./AnswerContext";
import { isAnserwed } from "./func";
import {Store} from "../../models/Attendance";

const GroupHead: React.FC<{
    name: string;
    questions: IQuestion[];
}> = React.memo(({ name, questions }) => {
    const answers = useContext(AnswerContext).value;
    const { length } = questions;
    const countAnswered = useMemo(() => {
        let count = 0;
        questions.map(question => {
            const { id } = question;
            const fields = answers[id];
            if (!fields) {
                return;
            }
            if (isAnserwed(fields)) {
                count++;
            }
        });
        return count;
    }, [answers, questions.length]);
    return (
        <span className={`ui button fluid ${countAnswered === length ? "facebook" : "grey"}`}>
            <strong className="badge badge-light">{countAnswered} / {length}</strong>
            <span>&nbsp; câu hỏi - {name}</span>
        </span>
    );
});

interface GroupQuestionsProps {
    questions: IQuestion[];
    group: Category | null;
    isSubmitting: boolean;
    collapsed?: boolean;
    hideGroupName?: boolean;
    category: Category;
    store: Store;
}

/**
 * Nhóm danh sách câu hỏi
 * @constructor
 */
const GroupQuestions: React.FC<GroupQuestionsProps> = ({ category, store, hideGroupName = false, collapsed, group, questions, isSubmitting }) => {
    // console.log(hideGroupName, collapsed);
    // Hiển thị danh sách câu hỏi
    const [active, setActive] = useState(hideGroupName ? true : collapsed);
    const name = useMemo(() => group ? group.name : "không có nhóm", [group?.name]);
    return (
        <tbody className={`tbody-overlap ${active ? "active" : ""}`}>
            {
                !hideGroupName && (
                    <tr className={"pointer"} onClick={() => setActive(prev => !prev)}>
                        <td className={"p-0"} colSpan={2}>
                            <GroupHead questions={questions} name={name} />
                        </td>
                    </tr>
                )
            }
            {
                questions.map((question) => {
                    return <Question
                        isSubmitting={isSubmitting}
                        key={question.id}
                        category={category}
                        store={store}
                        question={question} />
                })
            }
        </tbody>
    );
};

export default React.memo(GroupQuestions);
