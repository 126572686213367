import urls from "../settings/urls";
import {useState} from "react";
import {useCookie} from "react-use";
import constants from "../settings/constants";
import isArray from "lodash/isArray";
export interface Progress{
    status: null | "done" | "uploading" | "error";
    progress: number;
    response: any;
}

export type OnSnapShot = (data: Progress) => void;

interface Options {
    file: any;
    path: string;
}

export function upload({file, path}: Options, onSnapshot: OnSnapShot, token: string | null){
    const url = urls.uploadImage;
    const xhr = new XMLHttpRequest();
    xhr.upload.onprogress = function(e) {
        const progress = Math.floor(( e.loaded / e.total ) * 100);
        onSnapshot({status: "uploading", progress, response: null});
    };

    xhr.upload.onload = function(e) {
        onSnapshot({status: "uploading", progress: 0, response: null});
    };

    xhr.upload.onerror = function(e) {
        onSnapshot({status: "error", progress: 0, response: null});
    };

    xhr.onreadystatechange = function(){
        if(xhr.readyState === 4){
            onSnapshot({status: "done", progress: 100, response: xhr.response ? JSON.parse(xhr.response) : null});
        }
    };

    xhr.open("POST", url, true);
    xhr.setRequestHeader("Authorization", `Bearer ${token}`);
    const form = new FormData();
    if(isArray(file)){
        file.map(f => form.append("file[]", f))
    }else{
        form.append("file", file);
    }
    form.append("path", path);
    xhr.send(form);
}

const useUpload = (): [Progress, (file: any) => void] => {
    const [state, setState] = useState<Progress>({status: null, progress: 0, response: null});
    const [token] = useCookie(constants.AUTH_TOKEN);
    return [state, (file: any) => upload(file, setState, token)];
};

export default useUpload;
