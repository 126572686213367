import React, {useContext, useEffect, useMemo, useState} from "react";
import {Attendance, Scheduled} from "../../models/Attendance";
import useAttendance from "../../hooks/useAttendance";
import moment from "moment";
import {Button, Loader} from "semantic-ui-react";
import FormAttendance from "./FormAttendance";
import DayOffButton from "../../components/DayOff";
import AttendanceByStatus from "./AttendanceByStatus";
import {useSelector} from "react-redux";
import {Reducers} from "../../redux/reducers";
import StoreStatus from "../../components/StoreStatus";
import {AttendanceContext} from "../../contexts/AttendanceContext";

interface StaffAttendanceProps {
    scheduled: Scheduled;
}

const StaffAttendance: React.FC<StaffAttendanceProps> = ({scheduled}) => {
    const date = useMemo(() => moment().format("YYYY-MM-DD"), []);
    const result = useAttendance({date, staff_schedule_id: scheduled.id});
    const attendance = result.data ? new Attendance(result.data) : null;
    const [type, setType] = useState("attendance");
    const staff = useSelector((state: Reducers) => state.staffProfile);
    const {staff_schedule_id, onUpdate} = useContext(AttendanceContext);

    useEffect(() => {
        if (result?.data?.staff_schedule_id) {
            if(result?.data?.staff_schedule_id !== staff_schedule_id) {
                onUpdate(result?.data);
            }
        }
    }, [result, staff_schedule_id, onUpdate]);

    if (result.loading) {
        return <Loader active/>
    }

    if (result.data && result.data.status) {
        return <p className={"text-center font-weight-bold text-info"}>Tình trạng chấm công đã được thay đổi!</p>;
    }

    if (result.data && result.data.day_off) {
        return <p className={"text-center font-weight-bold text-info"}>Bạn đã báo hôm nay là ngày nghỉ phép vì vậy bạn
            không thể chấm công ngay lúc này, hãy thư giản nhé!</p>;
    }

    return (
        <div className="w-100 font-roboto">
            <div className={"d-flex align-items-center "}>
                <Button onClick={() => setType("attendance")} active={type === "attendance"}
                        className={"text-uppercase"}><strong>Chấm công</strong></Button>
                <Button disabled={!!(result.data && result.data?.times && result.data?.times.length)}
                        onClick={() => setType("dayOff")} active={type === "dayOff"}
                        className={"text-uppercase"}><strong>Nghỉ phép</strong></Button>
                {
                    staff && staff?.staff_role === "supervisor" && (
                        <>
                            <AttendanceByStatus scheduled={scheduled} status={"new-hire"} date={date}
                                                label={"New hire"}/>
                            <AttendanceByStatus scheduled={scheduled} status={"vacant"} date={date} label={"Vacant"}/>
                        </>
                    )
                }
            </div>
            {
                type === "attendance" ? <FormAttendance date={date} scheduled={scheduled} attendance={attendance}/> :
                    <DayOffButton date={date} scheduled={scheduled}/>
            }
        </div>
    );
};

export default React.memo(StaffAttendance);
