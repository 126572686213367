import {LoginParams} from "../hooks/useLogin";
import axios from "axios";
import urls from "../settings/urls";

export async function loginService(data: LoginParams) {
    return axios.post(urls.login, data);
}

export async function getAuthProfileService(token: string) {
    const headers = {Authorization: `Bearer ${token}`};
    return axios.get(urls.getAuthProfile, {headers});
}
