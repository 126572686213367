import React, {useEffect} from "react";
import useLogout from "../../hooks/useLogout";
import { Redirect } from "react-router-dom";
import {useDispatch} from "react-redux";
import {removeStaffProfile} from "../../redux/reducers/staffProfile";

const LogoutPage: React.FC = () => {
    useLogout(true);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(removeStaffProfile());
    },[dispatch]);

    return <Redirect to={"/"} />
};

export default LogoutPage;
