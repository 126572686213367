import React from "react";
import {Button} from "semantic-ui-react";
import {Link} from "react-router-dom";
import Logo from "../../components/Logo";

const WelcomePage: React.FC = () => {
    return (
        <div className="welcome_page page-center">
            <div className="container-fluid">
            <div className="flex-grow-1">
                <div className="row flex-grow-1 text-center justify-content-center align-items-center">
                    <div className="col-12 col-sm-6 col-md-6">
                        <div className="bg-white shadow-sm px-2 py-4 rounded">
                            <Logo>
                                <img src="images/location-marker.svg" alt="location marker" className="img-fluid location-marker"/>
                                <br/>
                                <h2 className="font-roboto mt-3"><strong>Checkin & Checkout</strong></h2>
                                <h2 className="display-3 font-weight-lighter font-roboto">on time</h2>
                                <br/>
                                <Link to={"/sign-in"}>
                                    <Button size='massive' className="rounded-pill btn-block shadow font-weight-lighter font-roboto"
                                            color={"orange"}>
                                        GET GPS
                                    </Button>
                                </Link>
                            </Logo>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    );
};

export default WelcomePage;
