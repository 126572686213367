import {AttendanceQuery} from "../services/attendance";
import {Attendance} from "../models/Attendance";
import {useFetch} from "./useFetch";
import urls from "../settings/urls";

const useAttendance = (data: AttendanceQuery) => {
    return useFetch<Attendance | null>(urls.attendance, data);
};

export default useAttendance;
