import React, {useEffect, useState} from "react";
import { Redirect } from "react-router-dom";

const AutoRedirect: React.FC<{
    timeout?: number;
    href: string;
    active: boolean
}> = ({timeout= 1000, href, active}) => {
    const [redirect, setRedirect] = useState(false);
    useEffect(() => {
        if(active){
            setTimeout(() => setRedirect(true), timeout);
        }
    }, [active, timeout]);
    if(!redirect) return null;
    return <Redirect to={href} />
};

export default React.memo(AutoRedirect);
