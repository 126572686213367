import React, {useState, useCallback} from "react";

const useForm = <T extends any>(defaultState: T): [T, (name: string | number) => any] => {
    const [state, setState] = useState(defaultState);

    const onChange = useCallback((name: string | number) => (e: React.ChangeEvent<any>) => {
        const {value, checked, type} = e.target;
        if(type === "radio" || type === "checkbox"){
            return setState((prev: any) => ({...prev, [name]: checked}));
        }
        return setState((prev: any) => ({...prev, [name]: value}));
    }, [setState]);

    return [state, onChange];
};

export default useForm;
