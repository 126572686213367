import {Input, TextArea, Label, Select} from "semantic-ui-react";
import React, {useCallback} from "react";
import Radio from "./fields/Radio";
import Checkbox from "./fields/Checkbox";
import random from "lodash/random";
import TakePhoto from "../TakePhoto";
import CurrencyInput from "./fields/Price";
import Repeat from "./fields/Repeat";

const Photo = (props: any) => {
    return <TakePhoto
        {...props}
        useCapture={true}
        label={props.placeholder}
        defaultValue={props.value}
    />
}

const MySelect = ({options, onChange, ...props}: any) => {
    options = options.map((node:any) => ({text: node.value, key:node.value, value: node.value}));
    const change = useCallback((e, {value}) => {
        onChange(value);
    }, [onChange]);
    return <Select {...props} options={options} onChange={change} />;
}

export const types: { [key: string]: any } = {
    select: MySelect,
    number: (props: any) => <Input fluid type="number" {...props} />,
    textarea: (props: any) => (
        <div className="ui form">
            {props.label && <Label className={"rounded-0"}>{props.label}</Label>}
            <TextArea fluid rows="3" {...props} />
        </div>
    ),
    email: (props: any) => <Input fluid type="email" {...props} />,
    radio: (props: any) => <Radio {...props} />,
    checkbox: (props: any) => {
        const id = `checkbox-${random(1000)}`;
        return <Checkbox  {...props} fluid checked={!!props.value} label={props.placeholder} type="checkbox" id={id}/>;
    },
    photo: Photo,
    repeat: Repeat,
    gallery: (props: any) => (
        <TakePhoto
            {...props}
            useCapture={false}
            label={props.placeholder}
            defaultValue={props.value}
        />
    ),
    price: ({label, ...props}: any) => (
        <div className="ui input fluid">
            <div className={"ui label"}>{label}</div>
            <CurrencyInput placeholder="$0.00" type="text" {...props} />
        </div>
    )
};
