import React, {useContext, useEffect, useMemo} from "react";
import Authenticated from "../../components/Authenticated";
import {Link} from "react-router-dom";
import Logo from "../../components/Logo";
import {Loader, Message} from "semantic-ui-react";
import {useFetch} from "../../hooks/useFetch";
import urls from "../../settings/urls";
import {Category} from "../../models/Category";
import useLogged from "../../hooks/useLogged";
import {AttendanceContext} from "../../contexts/AttendanceContext";
import {useStaff} from "../../hooks/useStaff";

const Menu: React.FC = () => {
    const isLogged = useLogged();
    const {staff_schedule_id} = useContext(AttendanceContext);
    const staff = useStaff();
    const {loading, data, errors} = useFetch<{ data: Category[] } | null>(isLogged ? urls.categories : null, {
        term: "question",
        "parent_id": null
    });

    const menus = useMemo(() => {
        let next = [];
        next.push({path: "/attendance", name: "Điểm danh"});
        if (data && staff_schedule_id) {
            data.data.map(node => next.push({name: node.name, path: `/question/${node.id}`}))
        }
        next.push({path: "/blog", name: "Tài liệu hướng dẫn"});
        if (staff?.id) {
            next.push({link: true, path: "/attendance/missing?staff=" + staff.id, name: "Tình trạng chấm công"});
        }
        next.push({path: "/logout", name: "Đăng xuất"});
        return next;
    }, [data, staff_schedule_id, staff]);

    return (
        <Authenticated>
            <div id="menu-page" className="p-md-2">
                <div className="bg-white shadow p-2 min-vh-100">
                    <Logo className="mb-3">
                        <div className="text-center font-weight-bold text-uppercase">
                            <strong>Màn hình chính</strong>
                        </div>
                    </Logo>
                    {loading && <Loader active/>}
                    <ul id="menu" className="list-unstyled">
                        {
                            menus.map(({name, path, link}, index) => {
                                if (link) {
                                    return (
                                        <li className="mb-3" key={index}>
                                            <a className="ui inverted button text-uppercase secondary"
                                               href={path}>
                                                {name}
                                            </a>
                                        </li>
                                    );
                                }
                                return (
                                    <li className="mb-3" key={index}>
                                        <Link className="ui inverted button text-uppercase secondary"
                                              to={path}>{name}</Link>
                                    </li>
                                );
                            })
                        }
                    </ul>
                    {errors && <Message color='red'>{errors.toString()}</Message>}
                </div>
            </div>
        </Authenticated>
    );
};

export default Menu;
