import { useState } from "react";
import { Response } from "../models/Response";
import axios from "axios";
import { sendError } from "../services/error";

export const useRequest = <T extends any>(uri?: string, method: any = "post"): [
    Response<T | null>,
    (data?: any, url?: string, type?: string) => Promise<void> | void] => {
    const [state, setState] = useState<Response<T | null>>({
        data: null,
        loading: false,
        errors: null,
        isFetched: false
    });
    return [
        state,
        async (data: any = {}, url = uri, type = method) => {
            if (state.loading) {
                return;
            }
            setState({ loading: true, data: null, errors: null });
            try {
                const response = await (axios as any)[type](url, data);
                setState({ loading: false, data: response.data, errors: null, isFetched: true });
                return response.data;
            } catch (errors) {
                if(errors.response){
                    setState({ loading: false, data: null, errors: errors.response.data, isFetched: true });
                }else{
                    url && sendError(url, JSON.stringify(errors), data);
                    setState({ loading: false, data: null, errors: ["Có lỗi kết nối, hãy kiểm tra lại đường truyền"], isFetched: true });
                }
                return Promise.reject(errors.response);
            }
        }
    ];
};
