import React from "react";
import useAuthenticated from "../../hooks/useAuthenticated";
import {Link} from "react-router-dom";
import {useStaff} from "../../hooks/useStaff";
const {name} =  (window as any)._INIT_DATA_;
const Logo: React.FC<{ className?: string }> = ({children, className = ""}) => {
    const isAuth = useAuthenticated();
    const staff = useStaff();
    return (
        <div className={`${className} header-logo mb-3`}>
            <div className="w-100 mb-2 text-center">
                <Link to={isAuth ? "/menu" : "/"}>
                    <img src="/themes/nevia/images/LogoDKSHSmollan.png" alt="Smollan Logo"
                         className="img-fluid mb-1 smollan-logo"/>
                </Link>
                <div style={{margin: "15px 0", padding: 10, fontSize: 20, background: 'blue', color: "#fff"}}>
                    <p><strong>DỰ ÁN { name }</strong></p>
                </div>
                {staff && <p className={"welcome"}>Xin chào <strong>{staff.staff_name}</strong></p>}
            </div>
            {children}
        </div>
    );
};

export default Logo;
