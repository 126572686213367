import React, {useMemo} from "react";
import {Schedule} from "../../models/Attendance";
import {Dropdown} from "semantic-ui-react";

interface ChooseTimesProps {
    schedule: Schedule;
    activeId: number | string;
    onChange(value: any): void;
}

const ChooseTimes: React.FC<ChooseTimesProps> = ({schedule, activeId, onChange}) => {

    const times = useMemo(() => {
        const {times} = schedule;
        return times.map(node => {
            return {
                value: node.id,
                text: node.time_name
            };
        });
    }, [schedule]);

    return (
        <div className="choose-times mt-3">
            <Dropdown
                placeholder='Chọn ca làm việc'
                fluid
                value={activeId}
                onChange={(e, {value}) => onChange(value)}
                selection
                options={times}
            />
        </div>
    );
};

export default ChooseTimes;
