import {useCookie} from "react-use";
import constants from "../settings/constants";
import {useSelector} from "react-redux";
import {Reducers} from "../redux/reducers";

const useAuthenticated = (): boolean => {
    const [token] = useCookie(constants.AUTH_TOKEN);
    const isLogged = useSelector((state: Reducers) => state.isLogin);
    return token ? true : isLogged;
};

export default useAuthenticated;
