import {Action, Reducer} from "redux";
import {Reducers} from "../reducers";

const UPDATE_FETCH = "UPDATE_FETCH";

export const saveFetch = (uri: string, data: any, save: any) => ({type: UPDATE_FETCH, uri, data, save});

export const getFetch = (uri: string, data: any) => (state: Reducers) => {
    const next = JSON.stringify({uri, data});
    return state.fetchCache[next] ? state.fetchCache[next] :null;
};

export const fetchReducer: Reducer<any, Action & {uri: string, data: any, save: any}> = (state = {}, {type, save, uri, data}) => {
    switch (type) {
        case UPDATE_FETCH:
            const next = JSON.stringify({uri, data});
            return {...state, [next]: save};
        default:
            return state;
    }
}

