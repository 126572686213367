import React, { useState } from "react";
import { Scheduled } from "../../models/Attendance";
import ChangeSchedule from "./ChangeSchedule";
import StaffAttendance from "./StaffAttendance";
import Authenticated from "../../components/Authenticated";
import Logo from "../../components/Logo";

const AttendancePage: React.FC = () => {
    const [scheduled, setScheduled] = useState<Scheduled | null>(null);
    return (
        <Authenticated>
            <div className="attendance_page p-md-2">
                <div className="bg-white shadow p-2 min-vh-100">
                    <Logo />
                    <br />
                    <ChangeSchedule disabled={false} activeId={scheduled?.id} onChange={setScheduled} />
                    <br />
                    {scheduled && <StaffAttendance scheduled={scheduled} />}
                </div>
            </div>
        </Authenticated>
    );
};

export default React.memo(AttendancePage);
