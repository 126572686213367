import React from "react";
import Logo from "./Logo";

const Layout: React.FC<any> = ({children}) => {
    return (
        <div className="w-100 page-center">
            <div className="container-fluid">
                <div className="row text-center justify-content-center align-items-center">
                    <div className="col-12 col-sm-7 col-md-6 min-vh-100 bg-white shadow px-2 py-4 w-100 rounded">
                        <Logo/>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
