import React, {useCallback, useMemo} from "react";
import isArray from "lodash/isArray";
import {random} from "lodash";

const Checkbox: React.FC<any> = ({label, onChange, value, checked}) => {
    const optionID = `radio-${random(1000000)}`;
    return (
        <div className="ui  checkbox fluid w-100">
            <input id={optionID} onChange={onChange} checked={checked} type="checkbox" value={value}/>
            <label htmlFor={optionID}>{value || label}</label>
        </div>
    );
};

const MyCheckbox: React.FC<any> = ({placeholder, classes, options, value, onChange}) => {
    const handleChange = useCallback(({target}: React.ChangeEvent<HTMLInputElement>) => {
        const next = value && isArray(value) ? value : [];
        if(target.checked){
            onChange([...next, target.value]);
        }else{
            onChange(next.filter(node => node !== target.value));
        }
    }, [value, onChange]);

    const isChecked = useCallback((target: any) => {
        const next = value && isArray(value) ? value : [];
        return !!next.find(node => node === target);
    }, [value]);

    return (
        <div className="ui form">
            <div className="grouped fields">
                <div className={classes}>
                    {placeholder && <label>{placeholder}</label>}
                    {
                        isArray(options) && options.length ? (
                            options.map((option, index) => {
                                return (
                                    <div className="field" key={index}>
                                        <Checkbox value={option.value} checked={isChecked(option.value)}
                                                  onChange={handleChange}/>
                                    </div>
                                );
                            })
                        ) : null
                    }
                </div>
            </div>
        </div>
    );
};

export default React.memo(MyCheckbox);
