import { env } from "../env";

const urls = {
    login: env.domain + "/api/login",
    getAuthProfile: env.domain + "/api/user",
    getStaffProfile: env.domain + "/api/auth/attendance/staff",

    schedules: env.domain + "/api/auth/attendance/schedules",
    attendance: env.domain + "/api/auth/attendance/attendance",
    dayOff: env.domain + "/api/auth/attendance/day-offs",

    categories: env.domain + "/api/categories",
    posts: env.domain + "/api/blog/posts",
    questions: env.domain + "/api/auth/question/questions",
    answer: env.domain + "/api/auth/question/answers",

    saveAttendance: env.domain + "/api/auth/attendance/save",

    uploadImage: env.domain + "/api/upload/image",
    error: env.domain + "/api/auth/question/errors",


};

export default urls;