import React, {useState} from 'react';
import {Button, Select} from 'semantic-ui-react';
import {Answer} from "../../models/Question";
import moment from 'moment';

const ChooseAnswer: React.FC<{
    answers: Answer[];
    onChange(answer: Answer | null): void;
}> = ({answers, onChange}) => {
    const [value, setValue ] = useState<any>(null);

    if (!answers.length) return null;

    const change = ($e: any, {value}: any) => {
        onChange(answers[+value]);
        setValue(value);
    }

    return (
        <div className="d-block">
            <label>Danh sách đã lưu thành công</label>
            <div className="d-flex">
                { value !== null && <Button onClick={() => {
                    onChange(null);
                    setValue(null);}
                }>HỦY CHỌN</Button> }
                <Select
                    value={value}
                    onChange={change}
                    style={{width: "100%", flex: 1}}
                    label
                    options={answers.map((answer, index) => {
                        return {
                            value: index,
                            text: moment(answer.updated_at).format('hh:mm:ss a')
                        };
                    })}
                />
            </div>
        </div>
    );
};

export default ChooseAnswer;
