import { Question as IQuestion } from "../../models/Question";
import React, { useCallback, useContext, useMemo } from "react";
import { AnswerContext } from "./AnswerContext";
import QuestionField from "./QuestionField";
import {Category} from "../../models/Category";
import {Store} from "../../models/Attendance";

export interface QuestionProps {
    question: IQuestion;
    isSubmitting: boolean;
    category?: Category;
    store?: Store;
}

const Question: React.FC<QuestionProps> = ({ category, store, question, isSubmitting }) => {
    const { value, onChange } = useContext(AnswerContext);
    const { id } = question;

    const answers = useMemo(() => {
        return value && value[id] ? value[id] : {};
    }, [id, value]);

    const handleChange = useCallback(
        (data: any) => {
            onChange({ ...value, [id]: data });
        },
        [onChange, value, id]
    );

    return <QuestionField
        onChange={handleChange}
        answers={answers}
        question={question}
        category={category}
        store={store}
        isSubmitting={isSubmitting}
    />
};

export default React.memo(Question);
