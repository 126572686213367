import React from "react";
import { Button, Input } from "semantic-ui-react";
import useForm from "../../hooks/useForm";
import { LoginParams, useLogin } from "../../hooks/useLogin";
import { Redirect } from "react-router-dom";
import useAuthenticated from "../../hooks/useAuthenticated";
import Logo from "../../components/Logo";

const LoginPage: React.FC = () => {
    const [data, onChange] = useForm<LoginParams>({ email: "", password: "" });
    const [response, login] = useLogin();
    const isLogged = useAuthenticated();
    if (isLogged) {
        return <Redirect to="/menu" />
    }

    return (
        <div className="login_page page-center">
            <div className="container-fluid">
                <div className="row text-center justify-content-center align-items-center">
                    <div className="col-12 col-sm-7 col-md-6">
                        <div className="bg-white shadow px-2 py-4 w-100 rounded">
                            <Logo />
                            {response.errors && <p className="text-danger">Sai thông tin tài khoản hoặc mật khẩu</p>}
                            <Input onChange={onChange("email")} value={data.email} size='large' className="mb-4" fluid
                                icon='users' iconPosition='left' placeholder='Tài khoản' />
                            <Input onChange={onChange("password")} value={data.password} size='large' type="password"
                                className="mb-4" fluid icon='key' iconPosition='left' placeholder='Mật khẩu' />
                            <Button loading={response.loading} onClick={() => login(data)} size='massive'
                                className="rounded-pill shadow font-weight-lighter font-roboto"
                                color={"orange"}>
                                ĐĂNG NHẬP
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
