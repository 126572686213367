import React, {useCallback, useEffect, useState} from "react";
import {Button, Checkbox} from "semantic-ui-react";
import {useRequest} from "../../hooks/useRequest";
import urls from "../../settings/urls";
import moment from "moment";
import {Scheduled} from "../../models/Attendance";
import useTimeOut from "../../hooks/useTimeOut";
import {Redirect} from "react-router-dom";
import {getDefinedOff} from "../../helpers/attendance";

interface DayOffButtonProps {
    scheduled: Scheduled;
    date: string;
}

const offs = getDefinedOff();

const DayOffButton: React.FC<DayOffButtonProps> = ({date, scheduled}) => {
    const [type, setOffType] = useState("week");
    const [request, onSubmit] = useRequest<any>(urls.saveAttendance);
    const handleSubmit = useCallback(() => {
        const data: any = {
            staff_schedule_id: scheduled.id,
            date,
            time: moment().format("LTS"),
            day_off_type: type
        };
        onSubmit(data);
    }, [date, onSubmit, scheduled.id, type]);
    const [timeOut, runTimeOut] = useTimeOut(3000);
    useEffect(() => {
        if (request.data) {
            runTimeOut();
        }
    }, [request, runTimeOut]);
    return (
        <div className="w-100">
            {timeOut && <Redirect to={'/menu'}/>}
            <div className={"w-100 mt-2"}>
                <p><strong>Loại nghỉ phép</strong></p>
                <div className={'w-100 my-2'}>
                    {
                        offs && offs.map(({name, value}: any) => {
                            return <Checkbox
                                key={value}
                                className={"m-1"}
                                checked={type === value}
                                onChange={() => setOffType(value)}
                                label={name}
                            />
                        })
                    }
                </div>
                <Button loading={request.loading} onClick={handleSubmit}
                        className={"btn-block mt-2 text-uppercase font-roboto"} size="large"
                        color={request.data ? "linkedin" : "instagram"}
                >
                    {request.data ? "Báo nghỉ phép hoàn tất" : "Tiến hành nghỉ phép"}
                </Button>
                {
                    request && request.errors &&
                    <p className="text-danger p-2 text-center">
                        Thất bại ! không có lịch nghỉ phép hoặc bạn đã hết
                    </p>
                }
            </div>
        </div>
    );
};

export default DayOffButton;
