import {useDispatch} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {logout} from "../redux/reducers/isLogin";
import {useCookie} from "react-use";
import constants from "../settings/constants";

const useLogout = (auto:boolean = false) => {
    const dispatch = useDispatch();
    const [,, delCookie] = useCookie(constants.AUTH_TOKEN);
    const [state, setState] = useState<boolean>(auto);

    const handleLogout = useCallback(() => {
        dispatch(logout());
        delCookie();
    }, [delCookie, dispatch]);

    useEffect(() => {
        if(state){
            handleLogout();
        }
    }, [state, handleLogout]);

    return () => setState(true)
};

export default useLogout;
