import imageCompression from 'browser-image-compression';
import Compressor from 'compressorjs';
export async function compress(files: File[], maxSizeMB: number = 0.3){
    const compressed: any[] = [];
    for (const file of files) {
        compressed.push(await imageCompression(file, {
            maxSizeMB,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            exifOrientation: 90
        }));
    }
    return compressed;
}

export async function compressor(file: File) {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.3,
            success: resolve,
            error: reject,
        });
    })
}