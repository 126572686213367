import React, { useEffect, useState } from "react";
import { useFetch } from "../../hooks/useFetch";

const Version: React.FC = () => {
    const [version, setVersion] = useState<string>();
    const [time, setTime] = useState(new Date().getTime());
    const result = useFetch<{ version: string }>(`version.json?time=${time}`, "get");

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date().getTime());
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (result.data && result.data.version) {
            if (version && version !== result.data.version) {
                alert("Bạn đang sử dụng phiên bản cũ, vui lòng tải lại App để cập nhập bản mới nhất để sử dụng");
                window.location.reload();
            }
            setVersion(result.data.version);
        }
    }, [result.data, version]);

    return (
        <div id="version">
            <span>{version}</span>
        </div>
    );
};

export default React.memo(Version);
