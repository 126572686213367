import React, {useCallback, useEffect} from "react";
import {Button} from "semantic-ui-react";
import {useRequest} from "../../hooks/useRequest";
import urls from "../../settings/urls";
import moment from "moment";
import { Scheduled} from "../../models/Attendance";
import useTimeOut from "../../hooks/useTimeOut";
import {Redirect} from "react-router-dom";

interface DayOffButtonProps {
    scheduled: Scheduled;
    status: string;
    date: string;
    label: string;
}

const AttendanceByStatus: React.FC<DayOffButtonProps> = ({date, status, label, scheduled}) => {
    const [request, onSubmit] = useRequest<any>(urls.saveAttendance);
    const handleSubmit = useCallback(() => {
        const data: any = {
            staff_schedule_id: scheduled.id,
            date,
            time: moment().format("LTS"),
            status
        };
        onSubmit(data);
    }, [date, onSubmit, status, scheduled.id]);
    const [timeOut, runTimeOut] = useTimeOut(3000);
    useEffect(() => {
        if (request.data) {
            runTimeOut();
        }
    }, [request, runTimeOut]);
    return (
        <>
            {timeOut && <Redirect to={'/menu'}/>}
            <Button loading={request.loading} onClick={handleSubmit}
                    className={"text-uppercase font-roboto"}
                    color={request.errors ? "google plus" : (request.data ? "linkedin" : undefined)}
            >
                {request.errors ? "Thất bại" : (request.data ? "Hoàn tất" : label) }
            </Button>
        </>
    );
};

export default AttendanceByStatus;
