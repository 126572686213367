import { GroupQuestion } from "../models/Question";
import { useFetch } from "./useFetch";
import urls from "../settings/urls";
import { useMemo } from "react";

const useQuestion = (category_id: any = null, data: any = {}) => {
    const url = useMemo(() => urls.questions + `/${category_id}`, [category_id]);
    return useFetch<GroupQuestion[] | null>(url, data)
};

export default useQuestion;
