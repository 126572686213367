
const constants = {
    AUTH_TOKEN: "AUTH_TOKEN"
};
const {attendance} = (window as any)._INIT_DATA_;

// Cho phép chọn ảnh từ thư viện để chấm công;
export const useCaptureCamera = !!attendance.useCapture;

// Sử dụng GPS khi chấm công
export const useGPS = !!attendance.useGPS;

export default constants;
