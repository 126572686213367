import React from "react";
import Logo from "../../components/Logo";
import {useFetch} from "../../hooks/useFetch";
import urls from "../../settings/urls";
import {Loader} from "semantic-ui-react";
import {Link} from "react-router-dom";
import Layout from "../../components/Layout";

const Blog: React.FC = () => {
    const {data, loading} = useFetch<any>(urls.posts);
    console.log(data);
    return (
        <Layout>
            <div className="w-100">
                {loading && <Loader/>}
                <h4>Tài liệu & Hướng dẫn</h4>
                <ul className={"list-unstyled mt-4"}>
                    {
                        data && (
                            data.length ? data.map((post: any) => {
                                return (
                                    <li key={post.id}>
                                        <Link className="w-100 d-block"
                                              to={`/blog/${post.guid.uri}`}>{post.post_title}</Link>
                                        <hr/>
                                    </li>
                                );
                            }) : (
                                <p>Không có nội dung</p>
                            )
                        )
                    }
                </ul>
                <Link to={"/menu"} className={"ui button mb-3 " + (loading ? "loading" : "")}><i className="reply icon"></i> Quay lại</Link>
            </div>
        </Layout>
    );
};

export default Blog;
