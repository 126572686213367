import React, { useCallback, useRef, useState, useMemo } from "react";
import { Button, Modal, Loader, Header, Input, Table, TableBody, Icon } from "semantic-ui-react";
import { GroupQuestion } from "../../../models/Question";
import { Question as IQuestion } from "../../../models/Question";
import Question from "../Question";

interface SearchQuestionProps {
    groups: GroupQuestion[];
    isSubmitting: boolean;
}

const SearchQuestion: React.FC<SearchQuestionProps> = ({ isSubmitting, groups }) => {
    const [active, setActive] = useState(false);
    const timeOut = useRef<any>();
    const toggle = () => setActive(prev => !prev);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [questions, setQuestions] = useState<IQuestion[]>([]);

    const handleSearch = useCallback((search: any) =>
        () => {
            const next = search.toLowerCase();
            const results: IQuestion[] = [];
            if (search !== "") {
                groups.map(({ questions }) => {
                    return questions.map(question => {
                        if (
                            question.code.search(new RegExp(`^${next}`)) !== -1 ||
                            question.question.toLowerCase().search(new RegExp(next)) !== -1
                        ) {
                            results.push(question);
                        }
                        return question;
                    });
                });
            }
            setQuestions(results);
            setLoading(false);
        }, [groups]);

    const onSearch = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearch(value);
        if (timeOut.current) {
            clearTimeout(timeOut.current);
        }
        setLoading(true);
        timeOut.current = setTimeout(handleSearch(value), 500);
    }, [setSearch, setLoading, handleSearch]);

    return (
        <div id="search_question">
            <div className="p-2">
                <Button type={"button"} onClick={toggle} circular><Icon name={"search"} />Tìm kiếm</Button>
            </div>
            <Modal open={active} onClose={toggle} className={'inverted'}>
                <Modal.Header>Tìm kiếm</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Header>
                            <div className="d-flex bg-dark rounded align-items-center">
                                <div className="flex-1 w-100">
                                    <Input className={"rounded-0"} value={search} onChange={onSearch} fluid
                                        label={"Nhập từ khóa"} />
                                </div>
                                {loading && <Loader className={"mx-2"} active inline inverted />}
                            </div>
                        </Header>
                        {search && <p>Tìm thấy <strong>{questions.length}</strong> kết quả</p>}
                    </Modal.Description>
                    <Table>
                        <TableBody>
                            {
                                questions.map(question => {
                                    return <Question
                                        isSubmitting={isSubmitting}
                                        key={question.id}
                                        question={question}
                                    />
                                })
                            }
                        </TableBody>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <Button type={"button"} primary onClick={toggle}>OK</Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default SearchQuestion;
