import React, {useEffect} from "react";
import {Redirect} from "react-router-dom";
import {Loader} from "semantic-ui-react";
import useAuthenticated from "../../hooks/useAuthenticated";
import useAuth from "../../hooks/useAuth";
import {useFetch} from "../../hooks/useFetch";
import urls from "../../settings/urls";
import {useDispatch, useSelector} from "react-redux";
import {updateStaffProfile} from "../../redux/reducers/staffProfile";
import {Staff} from "../../models/Staff";
import {Reducers} from "../../redux/reducers";

const Authenticated: React.FC = ({children}) => {
    const isLogged = useAuthenticated();
    const auth = useAuth();
    const staffProfile = useSelector((state: Reducers) => state.staffProfile);
    const fetchStaff = useFetch<Staff>(auth && isLogged && !staffProfile ? urls.getStaffProfile : "");
    const dispatch = useDispatch();

    useEffect(() => {
        if(fetchStaff.data){
            dispatch(updateStaffProfile(fetchStaff.data));
        }
    }, [fetchStaff.data, dispatch]);

    if (!isLogged) {
        return <Redirect to={"/sign-in"}/>
    }

    if (auth === null) {
        return <Loader/>;
    }

    return (
        <>
            {children}
        </>
    );
};

export default Authenticated;
