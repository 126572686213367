import React, {useContext, useEffect, useState} from "react";
import {Scheduled} from "../../models/Attendance";
import {Dimmer, Loader, Message, Dropdown} from "semantic-ui-react";
import isArray from "lodash/isArray";
import {useFetch} from "../../hooks/useFetch";
import urls from "../../settings/urls";
import useLogged from "../../hooks/useLogged";
import {AttendanceContext} from "../../contexts/AttendanceContext";

interface ChangeScheduleProps {
    activeId?: number;

    onChange(scheduled: Scheduled): void;

    disabled?: boolean
}

const ChangeSchedule: React.FC<ChangeScheduleProps> = ({disabled = true, onChange, activeId}) => {
    const {staff_schedule_id} = useContext(AttendanceContext);
    const isLogged = useLogged();
    const {loading, data, errors} = useFetch<Scheduled[]>(isLogged ? urls.schedules : null);

    React.useEffect(() => {
        if (data && data.length === 1) {
            onChange(data[0]);
        }
    }, [data, onChange]);

    const schedules = React.useMemo(() => {
        const schedules: any[] = [];
        isArray(data) && data
            .map((node) => {
                const {store, schedule} = node;
                schedules.push({
                    value: node.id,
                    key: store.store_name,
                    text: (
                        <div>
                            <p className="mb-0">{store.store_name}</p>
                            <p className="small mb-0">{schedule.schedule_name} - {schedule.description ?? 'không có mô tả'}</p>
                        </div>
                    )
                });
            })
        return schedules;
    }, [data]);

    const handleChange = React.useCallback((value) => {
        if (!isArray(data)) {
            return;
        }
        const find = data.find(node => node.id === value);
        if (find) {
            onChange(find);
        }
    }, [data, onChange]);

    useEffect(() => {
        if (typeof staff_schedule_id === "number") {
            handleChange(staff_schedule_id);
        }
    }, [handleChange, staff_schedule_id]);

    return (
        <div className="change_scheduled">
            <h5 className={"text-uppercase text-center mb-4"}><strong>Bạn có {data ? data.length : 0} lịch làm
                việc</strong></h5>
            {loading && <Dimmer active> <Loader/> </Dimmer>}
            {errors && <Message color='red'>{errors.toString()}</Message>}
            <Dropdown
                placeholder='Chọn lịch làm việc'
                fluid
                value={activeId}
                disabled={disabled}
                search={((options, value) => {
                    return options.filter(node => node.key.toLowerCase().search(value.toLowerCase()) !== -1)
                })}
                onChange={(e, {value}) => handleChange(value)}
                selection
                options={schedules}
            />
        </div>
    );
};

export default ChangeSchedule;
