import React from 'react';
import {Button} from "semantic-ui-react";
import {useRequest} from "../../hooks/useRequest";
import urls from "../../settings/urls";

const DeleteAnswer: React.FC<{ id: number, onDeleted(): void }> = ({id, onDeleted}) => {
    const [, request] = useRequest();

    const onClick = async () => {
        if(!window.confirm("Bạn có xác nhận xóa ?")) return;
        await request({id}, urls.answer + `/delete/${id}`, 'post');
        onDeleted();
    }

    return <Button onClick={onClick} type={"button"} color={"google plus"}>XÓA</Button>
}
export default DeleteAnswer;
