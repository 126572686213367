import {hot} from 'react-hot-loader/root';
import React, {useCallback, useEffect, useState} from 'react';
import {Switch, Route} from "react-router-dom";
import {routes} from "./routes";
import Version from "./components/Version";
import {useCookie, useGeolocation} from "react-use";
import {AddressContext} from "./Address.context";
import {AttendanceContext} from './contexts/AttendanceContext';

function geocodeLatLng(lat: any, lng: any) {
    return new Promise((resolve, reject) => {
        fetch(`https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&location=${lat},${lng}&token=AAPK9043417d5b9b40d8956ebb5241de609cGdlXhVhOVpaVhJ3USlfivgnSaeyKKwlgI7Lt1wAEl0bIrQE0boQ6lbJ-XlGf5xD_`)
            .then((response: any) => {
                resolve(response.json());
            });
    })
}

let bool = false;

function App() {
    const location = useGeolocation();
    const [address, setAddress] = useState<any>();
    const [staffScheduleId, setStaffScheduleId] = useState<number | null>(null);
    const [attendanceCace, setAttendanceCache] = useCookie('current_staff_schedule');

    useEffect(() => {
        if (!location || !location.latitude || bool) return;
        bool = true;
        // geocodeLatLng(location.longitude, location.latitude).then((result: any) => {
        //     setAddress(result?.address);
        // }).catch(console.error);
    }, [location]);

    const onUpdate = useCallback((attendance: any) => {
        console.log(attendance);
        if (attendance?.staff_schedule_id) {
            setStaffScheduleId(attendance?.staff_schedule_id);
            setAttendanceCache(JSON.stringify({staff_schedule_id: attendance?.staff_schedule_id, date: new Date().toDateString()}));
        }else{
            setStaffScheduleId(null)
            setAttendanceCache("");
        }
    }, [setAttendanceCache]);

    useEffect(() => {
        if (attendanceCace) {
            try {
                const {staff_schedule_id, date} = JSON.parse(attendanceCace);
                if (date === new Date().toDateString()) {
                    setStaffScheduleId(staff_schedule_id);
                }
            } catch (error) {
                /** Ignore **/
            }
        }
    }, [attendanceCace]);

    return (
        <AddressContext.Provider value={address}>
            <div className="App" style={{backgroundImage: `url("images/bg.jpg")`}}>
                <AttendanceContext.Provider value={{
                    staff_schedule_id: staffScheduleId,
                    onUpdate
                }}>
                    <Switch>
                        {
                            routes.map((route, index) => {
                                return <Route key={index} {...route} />
                            })
                        }
                    </Switch>
                </AttendanceContext.Provider>
                <Version/>
            </div>
        </AddressContext.Provider>
    );
}

export default hot(App);
