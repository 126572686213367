import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button} from "semantic-ui-react";
import Image from "../Image";
import {compressor} from "../../helpers/image";
import {Category} from "../../models/Category";
import {Store} from "../../models/Attendance";
import moment from "moment";
import {upload} from "../../hooks/useUpload";
import {useCookie} from "react-use";
import constants from "../../settings/constants";
import {AddressContext} from "../../Address.context";

const watermark = require('watermarkjs');

interface TakePhotoProps {
    defaultValue?: string;
    label?: string;
    useCapture?: boolean;
    category?: Category;
    store?: Store;

    onChange(e: React.ChangeEvent<HTMLInputElement>): void;

    required?: boolean;
}

function randomNumber(min: number, max: number) {
    return Math.random() * (max - min) + min;
}

const postX = (number: number) => (boat:any, metrics:any, context:any) => {
    return number;
};

const postY = (number: number) => (boat:any, metrics:any, context:any) => {
    return number;
};

const TakePhoto: React.FC<TakePhotoProps> = ({store, category, useCapture, required, label = "CHỤP ẢNH", onChange, defaultValue = ""}) => {
        const [photoURL, setPhotoURL] = useState<any>(defaultValue);
        const id = useMemo(() => `file-${randomNumber(0, 1000)}`, []);
        const [message, setMessage] = useState("");
        const [token] = useCookie(constants.AUTH_TOKEN);
        const address = React.useContext(AddressContext);
        const path = useMemo(() => {
            const date = moment().format("YYYY-MM-DD")
            let path = "";
            if (store) {
                if(store.store_area) {
                    path += `${store.store_area}/`;
                }
                if(store.mte) {
                    path += `${store.mte.staff_code}/`;
                }
                path += `${store.store_code}/${date}/`;
            }
            if (category) {
                path += `${category.name}/`;
            }
            return `${path}${label}`;
        }, [store, category, label]);

        useEffect(() => {
            if (!photoURL && defaultValue && defaultValue !== photoURL) {
                setPhotoURL(defaultValue);
            }
        }, [photoURL, defaultValue]);

        const handleUpload = useCallback((file) => {
                return new Promise(async (resolve, reject) => {
                    if (address) {
                        const {District, City, Neighborhood, Address} = address;
                        const txtAddress = `${Neighborhood ?? ''} ${District ?? ''} ${City ?? ''}`;
                        const next = await watermark([file])
                            .image(watermark.text.atPos(postX(12), postY(30), txtAddress, '28px Josefin Slab', '#fff', 0.8))
                            .render()
                            .image(watermark.text.atPos(postX(12), postY(70), Address, '28px Josefin Slab', '#fff', 0.8))
                            .render()
                            .image(watermark.text.atPos(postX(12), postY(110), new Date().toLocaleString(), '28px Josefin Slab', '#fff', 0.8))
                        file = await fetch(next.src).then(response => response.blob());
                    }
                    upload({file, path}, snapshot => {
                        setMessage(`Tải ảnh lên ${snapshot.progress}%`);
                        if (snapshot.status === "done") {
                            return resolve(snapshot.response);
                        } else if (snapshot.status === "error") {
                            return reject(snapshot.response);
                        }
                    }, token);
                })
            }, [token, path, address]
        )

        const onTakePhoto = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
            const {files} = e.target;
            if (!files || !files.length) {
                return;
            }
            setMessage("Đang nén ảnh...");
            const compressed = await compressor(files[0]);
            setMessage("Đang tải ảnh lên");
            handleUpload(compressed).then(({photo_url}: any) => {
                setPhotoURL(photo_url);
                onChange({target: {type: "file", value: photo_url}} as any);
                setMessage("Tải ảnh lên thành công");
            }).catch(error => {
                console.log(error);
                setMessage("Lỗi khi tải lên");
            });
        }, [setPhotoURL, onChange]);

        const fileProps = useMemo(() => {
            const data: any = {
                onChange: onTakePhoto,
                type: "file",
                className: "d-none",
                id: id,
                multiple: true,
                accept: "image/*"
            }
            if (useCapture) {
                data.capture = "user";
            }
            return data;
        }, [useCapture, onTakePhoto, id]);

        return (
            <div id="take_photo">
                {photoURL && <Image alt={"camera"} src={photoURL} className="img-fluid w-100 rounded shadow"/>}
                <input {...fileProps} />
                <label htmlFor={id} className="mt-2 d-flex mb-0 justify-content-center">
                    <Button type={"button"} primary={photoURL} as="div" className="btn-round mr-0 icon" variant="contained">
                        <i className="camera retro icon"/> {message || label}</Button>
                </label>
                {required &&
                <input onChange={e => e} value={photoURL} type="text" className="field-required-hide w-100" required/>}
            </div>
        );
    }
;

export default TakePhoto;
