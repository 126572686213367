import {Staff} from "../../models/Staff";
import {Action, Reducer} from "redux";

const UPDATE_STAFF_PROFILE = "UPDATE_STAFF_PROFILE";
const REMOVE_STAFF_PROFILE = "REMOVE_STAFF_PROFILE";

export const updateStaffProfile = (data: Staff) => ({data, type: UPDATE_STAFF_PROFILE});
export const removeStaffProfile = () => ({type: REMOVE_STAFF_PROFILE});

export const staffProfile: Reducer<Staff | null, { data: Staff } & Action> = (state = null, {type, data}) => {
    switch (type) {
        case UPDATE_STAFF_PROFILE:
            return state ? {...state, ...data} : data;
        case REMOVE_STAFF_PROFILE:
            return null;
        default:
            return state;
    }
};

