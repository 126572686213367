import {isLogin} from "./reducers/isLogin";
import {authProfile} from "./reducers/authProfile";
import {AuthProfile} from "../models/Auth";
import {fetchReducer} from "./reducers/fetch";
import {staffProfile} from "./reducers/staffProfile";
import {Staff} from "../models/Staff";

export const reducers = {
    isLogin: isLogin,
    authProfile: authProfile,
    fetchCache: fetchReducer,
    staffProfile: staffProfile
};

export interface Reducers {
    isLogin: boolean;
    authProfile: AuthProfile;
    fetchCache: any;
    staffProfile: Staff | null;
}
