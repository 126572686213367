import {useCallback, useState} from "react";
import {useDispatch} from "react-redux";
import {login} from "../redux/reducers/isLogin";
import {loginService} from "../services/auth";
import {useCookie} from "react-use";
import constants from "../settings/constants";

export interface LoginParams {
    email: string;
    password: string;
}

export interface LoginResponse {
    loading: boolean;
    data: any;
    errors: any;
}

export const useLogin = (): [LoginResponse, (data: LoginParams) => void] => {
    const dispatch = useDispatch();
    const [,setCookie] = useCookie(constants.AUTH_TOKEN);
    const [state, setState] = useState<LoginResponse>({loading: false, data: null, errors: null});

    const handleLogin = useCallback(async (params: LoginParams) => {
        setState({loading: true, data: null, errors: null});
        try{
            const {data} = await loginService(params);
            setState({loading: false, data, errors: null});
            setCookie(data.token);
            dispatch(login(data.token));
        }catch (e) {
            setState({loading: false, data: null, errors: "Sài thông tin tài khoản hoặc mật khẩu"});
        }
    }, [setState, dispatch, setCookie]);

    return [state, handleLogin];
};
