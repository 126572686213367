import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Icon, Loader, Message } from "semantic-ui-react";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import { Category } from "../../models/Category";
import { Breadcrumb } from 'semantic-ui-react'
import { useFetch } from "../../hooks/useFetch";
import urls from "../../settings/urls";

interface GroupProps {
    data: Category[];
    active: Category | null;
    onClick(active: Category): void;
}

const Group: React.FC<GroupProps> = ({ data, onClick, active }) => {
    return (
        <ul className={`list-unstyled`}>
            {
                isArray(data) && data.map(node => {
                    return (
                        <li key={node.id}>
                            <button onClick={() => onClick(node)} className={`${active && active.id === node.id ? "active" : ""}`}>
                                <strong>{node.name}</strong>
                            </button>
                        </li>
                    );
                })
            }
        </ul>
    );
}

const QuestionGroup: React.FC<{
    id: number,
    onClick(data: Category | null): void;
}> = ({ onClick, id }) => {
    const { loading, data, errors } = useFetch<{ data: Category[] } | null>(urls.categories, { term: "question", id });
    const [state, setState] = useState<Category | null>(null);
    const [queue, setQueue] = useState<Category[]>([]);

    useEffect(() => {
        if (state) {
            onClick(state);
        } else {
            onClick(null);
        }
    }, [onClick, state]);

    const handleBack = useCallback(() => {
        const next = [...queue];
        if (next.length < 2) {
            return;
        }
        setState(next[next.length - 2]);
        next.splice(next.length - 1, 1);
        setQueue([...next]);
    }, [queue, setQueue, setState]);

    const changeState = useCallback((next: Category) => {
        setState(next);
        setQueue((prev: any) => ([...prev, next]));
    }, [setState, setQueue]);

    useEffect(() => {
        if (isObject(data) && isArray(data.data) && data.data.length === 1) {
            const next = data.data[0];
            changeState(next);
        }
    }, [data, changeState]);

    const categories: Category[] = useMemo(() => (isObject(data) && isArray(data.data) ? data.data : []), [data]);

    const groups = state ? state.children : categories;

    const sections = useMemo(() => {
        const data: any[] = [];
        queue.map((node, index) => {
            if (index === 0) {
                return null;
            }
            return data.push({ key: node.id, content: node.name });
        });
        return data;
    }, [queue]);

    return (
        <div className="group_question mb-2">
            {categories.length ? <h5 className={"text-center mb-3 text-uppercase"}><strong>{categories[0].name}</strong></h5> : null}
            {errors && <Message color='red'>{errors.toString()}</Message>}
            {loading && <Loader active />}
            {sections.length ? <Breadcrumb className={"w-100 mb-2"} sections={sections} icon={"right angle"} /> : null}
            {queue.length >= 2 ?
                <Button secondary className={"mb-2"} onClick={handleBack}><Icon name={"arrow left"} /> Quay
                    lại</Button> : null}
            {groups && <Group active={state} onClick={changeState} data={groups} />}
        </div>
    );
};

export default QuestionGroup;
