import React from "react";
import Layout from "../../components/Layout";
import {Loader} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {useFetch} from "../../hooks/useFetch";
import urls from "../../settings/urls";

const Single: React.FC<any> = ({match: {params: {slug}}}) => {
    const {data, loading, isFetched} = useFetch<any>(urls.posts + "/" + slug);
    return (
        <Layout>
            <div className="w-100 text-left">
                <Link to={"/blog"} className={"ui button mb-3 " + (loading ? "loading" : "")}><i className="reply icon"></i> Quay lại</Link>
                {loading && <Loader/>}
                {
                    isFetched && (
                        data ? (
                            <div className="post">
                                <h3 className="post-title">{data.post_title}</h3>
                                <hr/>
                                <div className="post-content" dangerouslySetInnerHTML={{__html: data.post_content}}/>
                            </div>
                        ) : <p>Không tìm thấy bài viết</p>
                    )
                }
            </div>
        </Layout>
    );
};

export default Single;
