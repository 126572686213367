import {Action, Reducer} from "redux";
import {AuthProfile} from "../../models/Auth";

export const UPD_AUTH_PROFILE = "UPD_AUTH_PROFILE";
export const DEL_AUTH_PROFILE = "DEL_AUTH_PROFILE";

export const updateAuthProfile = (data: AuthProfile) => ({type: UPD_AUTH_PROFILE, data});
export const deleteAuthProfile = () => ({type: DEL_AUTH_PROFILE});

export const authProfile: Reducer<AuthProfile | null, { data: AuthProfile } & Action> = (state = null, action) => {
    switch (action.type) {
        case UPD_AUTH_PROFILE:
            return state ? {...state, ...action.data} : {...action.data};
        case DEL_AUTH_PROFILE:
            return null;
        default:
            return state;
    }
};

