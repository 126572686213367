import {useCookie} from "react-use";
import constants from "../settings/constants";
import {useDispatch, useSelector} from "react-redux";
import {Reducers} from "../redux/reducers";
import {useEffect} from "react";
import {getAuthProfileService} from "../services/auth";
import {updateAuthProfile} from "../redux/reducers/authProfile";
import {login} from "../redux/reducers/isLogin";
import useLogout from "./useLogout";

const useAuth = () => {
    const dispatch = useDispatch();
    const logout = useLogout();
    const [token, , delCookie] = useCookie(constants.AUTH_TOKEN);

    const {authProfile, isLogin} = useSelector((state: Reducers) => ({
        isLogin: state.isLogin,
        authProfile: state.authProfile
    }));

    useEffect(() => {
        if(authProfile === null && token){
            getAuthProfileService(token).then(({data}) => {
                dispatch(updateAuthProfile(data));
                if(!isLogin){
                    dispatch(login(token));
                }
            }).catch(() => {
                window.alert("Không thể lấy thông tin tài khoản, vui lòng đăng nhập lại");
                logout();
                window.location.href = "/";
                delCookie();
            });
        }
        return () => {};
    }, [authProfile, logout, token, dispatch, delCookie, isLogin]);

    return authProfile;
};

export default useAuth;
