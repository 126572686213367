import {Staff} from "./Staff";

export interface Timestamps {
    created_at: string;
    updated_at: string;
}

export interface Schedule extends Timestamps {
    date_start: string;
    deleted_at: string;
    description: string;
    id: number;
    is_free_time: boolean;
    schedule_code: string;
    schedule_name: string;
    time_late: string;
    total_time: string;
    times: ScheduleTime[];
}

export interface AttendanceTime {
    attendance_id: number;
    distance: number;
    geo_accuracy: number;
    geo_coords: {
        type: string;
        coordinates: string[];
    }
    id: number;
    is_violate: boolean;
    photo_url: number;
    schedule_time_id: number;
    time: number;
    type: string;
}

export interface DayOff extends Timestamps {
    attendance_id: number;
    is_week_off: boolean;
    day_off_schedule_id: number;
    id: number;
}

export interface Attendance extends Timestamps {
    date: string;
    id: number;
    status: string;
    is_violate: boolean;
    schedule_id: number;
    staff_id: number;
    staff_schedule_id: number;
    store_id: number;
    total_working_time: string;
    day_off: DayOff;
    times: AttendanceTime[];
    store_opening_status?: string;
    store_is_opening?: boolean;
}

export interface Store extends Timestamps {
    geo_coords: string;
    id: number;
    store_address: string;
    store_area: string;
    store_city: string;
    store_classification: string;
    store_code: string;
    store_group: string;
    store_name: string;
    store_type: string;
    mtm?: Staff;
    mte?: Staff;
    am?: Staff;
}


export interface Scheduled extends Timestamps {
    id: number;
    is_active: boolean;
    schedule: Schedule;
    store: Store;
    staff_id: number;
}

export interface ScheduleTime extends Timestamps {
    check_in_time: string;
    check_out_time: string;
    id: number;
    order: number;
    schedule_id: number;
    time_name: string;
}

export class Attendance {
    constructor(public attendance: Attendance) {
    }

    public getTime(schedule_time_id: number, type: string) {
        return this.attendance.times.find(node => node.schedule_time_id === schedule_time_id && node.type === type);
    }
}
