import React, {useState} from "react";
import {Schedule, Attendance} from "../../models/Attendance";
import {List, Button} from "semantic-ui-react";
import {IState} from "./FormAttendance";
import ChooseTimes from "./ChooseTimes";
import moment from "moment";

interface ChooseShiftProps {
    schedule: Schedule;
    attendance: Attendance | null;
    value: IState;

    onChange(data: IState): void;

    disabled?: boolean;
}

const ChooseShift: React.FC<ChooseShiftProps> = ({disabled, value, schedule, attendance, onChange}) => {
    const [changed, setChanged] = useState<string | number>("");
    const showChooseTimes = schedule.times && schedule.times.length > 2;

    return (
        <>
            {showChooseTimes && <ChooseTimes onChange={setChanged} activeId={changed} schedule={schedule}/>}
            <List divided>
                {
                    schedule.times && schedule.times.map((node, index) => {

                        if (showChooseTimes && node.id !== changed) {
                            return null;
                        }

                        const checkIn = (attendance && attendance.getTime(node.id, "check-in"));
                        const checkOut = (attendance && attendance.getTime(node.id, "check-out"));
                        const active_1 = value.time?.id === node.id && value.type === "check-in";
                        const active_2 = value.time?.id === node.id && value.type === "check-out";

                        const exists_1 = !!checkIn;
                        const exists_2 = !!checkOut;

                        /**
                         * Dùng cho các ca chấm công nằm ở giữa không cần chụp hình
                         */
                        const hasPrevSchedule = showChooseTimes ? false : !!schedule.times[index - 1];
                        const hasNextSchedule = showChooseTimes ? false : !!schedule.times[index + 1];

                        return (
                            <List.Item key={node.id} className={"mb-3 pt-2"}>
                                <List.Content>
                                    {!showChooseTimes && <List.Header>{node.time_name}</List.Header>}
                                    <div className="d-flex mt-2">
                                        <Button disabled={disabled || exists_1 || active_1}
                                                onClick={() => onChange({
                                                    time: node,
                                                    type: "check-in",
                                                    // useCapture: true
                                                    useCapture: !hasPrevSchedule
                                                })}
                                                className={"mr-2 w-50"} color={"facebook"}>
                                            {exists_1 && "Đã"} {active_1 && "Đang"} Check In
                                            <div className="w-100">
                                                <small>{moment(checkIn ? checkIn.time : node.check_in_time, "h:mm:ss").format("LT")}</small>
                                            </div>
                                        </Button>
                                        <Button disabled={disabled || exists_2 || active_2}
                                                onClick={() => onChange({
                                                    time: node,
                                                    type: "check-out",
                                                    // useCapture: true
                                                    useCapture: !hasNextSchedule
                                                })}
                                                color={"orange"} className={"w-50"}>
                                            {exists_2 && "Đã"} {active_2 && "Đang"} Check Out
                                            <div className="w-100">
                                                <small>{moment(checkOut ? checkOut.time : node.check_out_time, "hh:mm:ss").format("LT")}</small>
                                            </div>
                                        </Button>
                                    </div>
                                </List.Content>
                            </List.Item>
                        );
                    })
                }
            </List>
        </>
    )
        ;
};
export default React.memo(ChooseShift);