import { lazy } from "react";
import { RouteProps } from "react-router-dom";
import LoginPage from "./pages/Login";
import Menu from "./pages/Menu";
import LogoutPage from "./pages/Logout";
import QuestionPage from "./pages/Question";
import AttendancePage from "./pages/Attendance";
import ThankPage from "./pages/Thank";
import WelcomePage from "./pages/Welcome";
import Blog from "./pages/Blog";
import Single from "./pages/Blog/single";

interface Route extends RouteProps { }

export const routes: Route[] = [
    {
        component: LoginPage,
        path: "/sign-in"
    },
    {
        component: Menu,
        path: "/menu"
    },
    {
        component: LogoutPage,
        path: "/logout"
    },
    {
        component: QuestionPage,
        path: "/question/:id"
    },
    {
        component: AttendancePage,
        path: "/attendance"
    },
    {
        component: ThankPage,
        path: "/thank"
    },
    {
        component: Single,
        path: "/blog/:slug"
    },
    {
        component: Blog,
        path: "/blog"
    },
    {
        component: WelcomePage,
        exact: true,
        path: ""
    },
    {
        component: lazy(() => import("./pages/Page404")),
        path: ""
    },
];
