import React from "react";

export const AnswerContext = React.createContext<{
    value: { [key: string]: any };
    onChange(value: any): void;
}>({
    value: {},
    onChange(value: any): void {
    }
});
